
import { toastController, IonContent, IonSlide, IonSlides, IonItem, IonGrid, IonRow, IonCol, IonModal, IonPage, IonHeader, IonText, IonToolbar, IonButtons, IonButton, IonIcon, IonRippleEffect, IonActionSheet, modalController, IonAlert, IonToast, useBackButton } from '@ionic/vue';
import { expand, shareSocial , locationOutline, informationCircleOutline, close } from 'ionicons/icons';
import { ComponentOptions, computed, defineComponent, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { BaseUrl } from '../../util/vars';
import { useStore } from '@/store';
import AudioPlayer from '../AudioPlayer.vue';
import VideoPlayer from '../VideoPlayer.vue';
import AudioPlayerPlaceHolder from '../place_holders/AudioPlayerPlaceHolder.vue'
import { useRoute } from 'vue-router';
import NumpadComponent from '../NumpadComponent.vue';
import { useI18n } from 'vue-i18n';
import { navigateLang } from '../../main'
import ImageZoomModal from '../ImageZoomModal.vue';
import { isPlatform } from '@ionic/vue';
import { getCurrentLangLocale } from '../../main'
import { Share } from '@capacitor/share';
import type { Image } from '@/types';
import HTMLIonSlidesElement from '@ionic/core';
import HTMLIonButtonElement from '@ionic/core';
import HTMLCollectionOf from "typeScript"

export default defineComponent({
  name: 'TourPage',
  components: {
    IonContent,
    IonSlides,
    IonSlide,
    IonItem,
    AudioPlayer,
    IonGrid, IonRow, IonCol, IonModal,
    IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon,
    IonRippleEffect,
    IonActionSheet,
    VideoPlayer,
    AudioPlayerPlaceHolder,
    NumpadComponent,
    IonAlert, IonToast, IonText
  },
  props: {
    pageData: {
      type: Object,
      required: true
    }
  },
  emits: ["on-content-scroll"],
  setup (props: ComponentOptions) {
    const { t } = useI18n()
    let myPane: CupertinoPane;
    const isDrawerPresented = ref(false)
    const isBeginning = ref(true);
    const isEnd = ref(false);
    const currentWorkIndex = ref(0)
    const selectedWork = ref(0);
    const totalWorks = ref(0)
    const imagesTobeLoaded = ref(10)
    const isWorkDataReady = ref(false);
    const store = useStore()
    const workPageData = store.state.workPage;
    const route = useRoute();
    const disableSliding = ref(false);
    // modal related
    const isModalPresented = ref(false);
    const setModalOpened = (state: boolean) => isModalPresented.value = state;
    // action-sheet realted
    const isActionSheetOpen = ref(false);
    const setActionSheetOpened = (state: boolean) => isActionSheetOpen.value = state;
    const user = store.state.user
    const { locale } = useI18n()
    const isPlaying = ref(false);
    const isToastOpen = ref(false);
    const setToastOpen = (state: boolean) => isToastOpen.value = state;
    const isLandscape = ref(window.matchMedia("(orientation: landscape)").matches);
    const viewportWidth = ref(window.innerWidth);

    const getPaneBreaks = () => {
      const topHeight = isLandscape.value && window.innerWidth < 1140 ? 220 : 450
      viewportWidth.value = window.innerWidth;
      return {
        top: {
          enabled: true,
          height: topHeight,
          bounce: true
        },
        middle: {
          enabled: false,
        },
        bottom: {
          enabled: true,
          height: viewportWidth.value >= 1140 ? 150 : 80,
          bounce: true
        }
      }
    }
    const settings: CupertinoSettings = {
      buttonDestroy: false,
      showDraggable: false,
      draggableOver: false,
      initialBreak: 'bottom',
      clickBottomOpen: false,
      lowerThanBottom: false,
      breaks: getPaneBreaks(),
      events: {
        onTransitionEnd: () => {
          setTimeout(() => {
            if(myPane.currentBreak() === 'top') {
              isDrawerPresented.value = true
            } else {
              isDrawerPresented.value = false
            }
          }, 50);
        }
      }
    }

    const slideOpts = {
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
        renderProgressbar: function (progressbarFillClass: any) {
          return '<span class="' + progressbarFillClass + '"></span>';
        }
      }
    };


    onMounted(() => {
      totalWorks.value = props.pageData.Works.length
      myPane = new CupertinoPane('.cupertino-pane', settings);
      myPane.present({ animate: true });
      window.addEventListener('resize', () => {
        isLandscape.value = window.matchMedia("(orientation: landscape)").matches;

        setTimeout(async () => {
          const breaks = getPaneBreaks()
          await myPane.setBreakpoints(breaks)
        }, 1000);
        
      })
      
      var workId = route.params.workId ? parseInt(route.params.workId.toString()) : props.pageData.Works[0].ID
      selectedWork.value = workId
      navigateLang('/page/' + props.pageData.ID + '/' + workId, true)
    })

    watchEffect(async () => {
      if(route.params.workId) {
        isWorkDataReady.value = false;
        
        await store.getWorkPageData(Number(route.params.workId))
        if (store.state.workPage.value?.ID === Number(route.params.workId)) {
          isWorkDataReady.value = true;
          colseNumpad()
        }
      }
    })
    
    const prevSlide = async () => {
      document.querySelector<HTMLIonSlidesElement>('ion-slides#works-slides')?.slidePrev();
      setTimeout(() => {
        const activeSlider = document.querySelector<HTMLElement>('.swiper-slide-active')
        if (activeSlider) {
          activeSlider.focus()
        }
      }, 1000);
    }
    const nextSlide = () => {
      document.querySelector<HTMLIonSlidesElement>('ion-slides#works-slides')?.slideNext();
      setTimeout(() => {
        const activeSlider = document.querySelector<HTMLElement>('.swiper-slide-active')
        if (activeSlider) {
          activeSlider.focus()
        }
      }, 1000);
    }

    const initSilder = (event: any) => {
      const slider = event.srcElement;
      const activeSlider = slider.querySelector('.swiper-slide-active');
      setTimeout(() => {
        if (activeSlider) {
          activeSlider.focus()
        }
      }, 1000);
      updateAudioPlayBtnTabIndex(activeSlider);
      const slidesIndex = props.pageData.Works.findIndex( (element: any) => { return element.ID === parseInt(route.params.workId.toString())})
      slider?.slideTo(slidesIndex)
    }

    const updateSelectedWork = async (event: any) => {
      const slider = event.srcElement;
      currentWorkIndex.value = await slider.getActiveIndex()
      const activeSlider = slider.querySelector('.swiper-slide-active');
      navigateLang('/page/' + props.pageData.ID + '/' + parseInt(activeSlider.getAttribute('data-id')), true)
      isEnd.value = await slider.isEnd();
      isBeginning.value = await slider.isBeginning();
      selectedWork.value = parseInt(activeSlider.getAttribute('data-id'))
      isPlaying.value = false;
      updateAudioPlayBtnTabIndex(activeSlider)

      var currentIndex = activeSlider.getAttribute('data-index')
      imagesTobeLoaded.value = parseInt(currentIndex)
      if (imagesTobeLoaded.value < totalWorks.value) {
        let leftWorks = totalWorks.value - imagesTobeLoaded.value
        if (leftWorks < 10) {
          const val = imagesTobeLoaded.value
          imagesTobeLoaded.value = val + leftWorks
        } else {
          const val = imagesTobeLoaded.value
          imagesTobeLoaded.value = val + 10
        }
      }
    }



    const presentDrawer = () => {
      isNumpadPresented.value = false

      if(myPane.currentBreak() !== 'top') {
        myPane.moveToBreak('top');
        setTimeout(() => {
          document.querySelector<HTMLIonButtonElement>('.close-btn')?.focus()
        }, 0); 
        var worksItems = document.getElementsByClassName("work-item-ref") as HTMLCollectionOf<HTMLElement>
        for (let workItem of worksItems) {
          // workItem.tabIndex = 0
          workItem.setAttribute("aria-hidden", "undefined") 
        }

        handleIonHeaderWhenDrawerOpened(true)
      }
    }

    const closeDrawer = () => {
      if(myPane.currentBreak() !== 'bottom') {
        var worksItems = document.getElementsByClassName("work-item-ref") as HTMLCollectionOf<HTMLElement>
        for (let workItem of worksItems) {
          // workItem.tabIndex = -1
          workItem.setAttribute("aria-hidden", "true") 
        }
        myPane.moveToBreak('bottom');

        handleIonHeaderWhenDrawerOpened(false)
      }
    }

    const handleIonHeaderWhenDrawerOpened = (isOpened: boolean) => {
      const ionHeader = document.querySelector('ion-header')
      if (ionHeader) {
        if (isOpened) {
          ionHeader.style.zIndex = '0'
        } else {
          ionHeader.style.zIndex = '1'
        }
      }
    }

    const between = (x: number, max: number) => {
      return x >= 0 && x <= max;
    }

    const updateSlider = (index: number, workID: number) => {
      navigateLang('/page/' + props.pageData.ID + '/' + workID, true)
      const slider = document.querySelector<HTMLIonSlidesElement>('ion-slides#works-slides');
      slider?.slideTo(index).then(() => {
        closeDrawer();
      })
    }

    const onWorkChoosed = (index: number) => {
      const slider = document.querySelector<HTMLIonSlidesElement>('ion-slides#works-slides');
      slider?.slideTo(index).then(() => {
        closeDrawer();
      })
    }

    const updateAudioPlayBtnTabIndex = (activeSlider: HTMLElement) => {
      var svgBtns = document.getElementsByClassName("svg-btn") as HTMLCollectionOf<HTMLElement>
      for (let svgBtn of svgBtns) {
        // svgBtn.tabIndex = -1
        svgBtn.setAttribute("aria-hidden", "true") 
      }
      setTimeout(()=>{
        const activeSvgBtn = activeSlider.querySelector('.svg-btn') as HTMLElement
        if(activeSvgBtn) {
          // activeSvgBtn.tabIndex = 0
          activeSvgBtn.setAttribute("aria-hidden", "undefined") 
        }
      }, 2000)
    }    

    const lang = getCurrentLangLocale() ? getCurrentLangLocale() + '/' : ''
    const share = (message: string, subject: string|undefined, tourID: number, workID: number|undefined)  => {
      const url = process.env.VUE_APP_WEB_BASE_URL + '#' + lang + '/page/' + tourID + '/' + workID;
      Share.share(
        {
          title: subject,
          text: message,
          url: url,
          dialogTitle: t('message.shareImage'),
        }
      );
    }

    const buttons = [
      {
        text: t('message.close'),
        icon: close,
        cssClass: 'light',
        handler: () => {
          setActionSheetOpened(false)
        },
      },
      {
        text: t('message.exploreDetails'),
        icon: expand,
        handler: () => {
          if(workPageData.value?.Image)
            previewImage(workPageData.value?.Image)
          else 
            openToast(t('message.noImage'))
        },
      },
      {
        text: t('message.shareImage'),
        icon: shareSocial,
        handler: () => {
          if(!isPlatform("desktop") && !isPlatform('mobileweb')) {
            share('Kunsthalle Bremen Art Surfer', workPageData.value?.Title, props.pageData.ID , workPageData.value?.ID)
          } else {
            setShareAlertOpen(true)
          }
        },
      },
    ];

    const openToast = async (message: string) => {
      const toast = await toastController
        .create({
          message: message,
          duration: 2000
        })
      return toast.present();
    }

    const previewImage = async (img: Image) => {
      const modal = await modalController
      .create({
        component: ImageZoomModal,
        cssClass: 'image-zoom-modal-class',
        componentProps: {
          img
        }
      })
      return modal.present().then(() => {
        modal.focus()
      });
    }

    
    const onPlayStateChanged = (state: boolean) => {
      isPlaying.value = state;
      colseNumpad();
    }

    
    const visitedWorksIds = computed(() => {
      if (locale.value == 'en') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.en) {
            return user.value.visitedWorksIds.en
          }
        }
      }
      if (locale.value == 'de') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de
          }
        }
      }
      if (locale.value == 'easy') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de
          }
        }
      }
      if (locale.value == 'ar') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de
          }
        }
      }
      if (locale.value == 'ru') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de
          }
        }
      }
      if (locale.value == 'fr') {
        if (user.value?.visitedWorksIds) {
          if (user.value?.visitedWorksIds.de) {
            return user.value.visitedWorksIds.de
          }
        }
      }
      
      return []
    })

    const isNumpadPresented = ref(false)
    const presentNumpad = () => {
      if(isNumpadPresented.value) {
        isNumpadPresented.value = false
        handleIonHeaderWhenDrawerOpened(false)
      } else {
        closeDrawer()
        isNumpadPresented.value = true
        setTimeout(() => {
          const numpadDisplay = document.querySelector('.c-numpad') as HTMLElement
          if(numpadDisplay) numpadDisplay.focus()
        }, 1000);
        handleIonHeaderWhenDrawerOpened(true)
      }
    }
    const colseNumpad = () => {
      isNumpadPresented.value = false
      handleIonHeaderWhenDrawerOpened(false)
    }

    
    const loadImage = (idName: string, url: string | undefined, thumb: string | undefined, imageImportantArea: string | undefined) => {
      const ImageImportantArea = imageImportantArea ? imageImportantArea : 'center'
      return 'background: url('+ thumb +') no-repeat ' + ImageImportantArea + '/cover;'
    }

    const isShareAlertOpen = ref(false);
    const setShareAlertOpen = (state: boolean) => isShareAlertOpen.value = state;
    
    const copyToClipboard = (url: string) => {
      let temp = document.createElement('textarea');
      temp.value = url;
      document.body.appendChild(temp);
      temp.select();
      document.execCommand('copy');
      document.body.removeChild(temp);
      setToastOpen(true)
    }

    const geturlToCopy = (workID: number | undefined) => {
      return process.env.VUE_APP_WEB_BASE_URL + '#' + lang + '/page/' + props.pageData.ID + '/' + Number(workID)
    }
    const getShareInputs = () => {
      return [
        {
          name: 'work-url',
          id: 'work-url',
          type: 'url',
          value: geturlToCopy(workPageData.value?.ID),
          placeholder: 'Url',
        }
      ]
    } 
    const shareButtons =  [
      {
        text: t('message.close'),
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          setShareAlertOpen(false)
        }
      },
      {
        text: t('message.copy'),
        handler: () => {
          copyToClipboard(geturlToCopy(workPageData.value?.ID))
          setShareAlertOpen(false)
        }
      }
    ]

    const background = (viewtype: string, url: string, noRights: boolean, dominantColor: any, thumb: string, index: number, imageImportantArea: string) => {
      if(viewtype === 'small') {
        return 'background:rgb(' + dominantColor.R + ',' + dominantColor.G + ',' + dominantColor.G + ')';
      } else {
        if(noRights) {
          return 'background: url(' + url + ') no-repeat center/cover;';
        } else {
          const ImageImportantArea = imageImportantArea ? imageImportantArea : 'center'
          return 'background: url('+ BaseUrl + 'xpedeo/' + url +') no-repeat '+ ImageImportantArea +'/cover;';
        }
      }
    }

    useBackButton(100, (processNextHandler: any) => {
      if (isActionSheetOpen.value) {
        isActionSheetOpen.value = false
      } else {
        processNextHandler()
      }
    })

    const modalDidPresented = () => {
      const unkownEl = document.querySelector('.sc-ion-modal-md[tabindex="0"]')
      const backdrop = document.querySelector('ion-backdrop')
      if (unkownEl) {
        unkownEl.setAttribute('aria-hidden', 'true')
      }
      if (backdrop) {
        backdrop.setAttribute('aria-hidden', 'true')
      }
    }

    onBeforeUnmount(() => {
      myPane.destroy({ animate: true })
    })

    return {
      presentDrawer,
      BaseUrl,
      slideOpts,
      updateSelectedWork,
      selectedWork,
      updateSlider,
      isDrawerPresented,
      closeDrawer,
      workPageData,
      isWorkDataReady,
      isModalPresented,
      setModalOpened,
      isActionSheetOpen,
      setActionSheetOpened,
      buttons,
      isPlaying,
      onPlayStateChanged,
      prevSlide,
      nextSlide,
      isEnd,
      isBeginning,
      locationOutline,
      visitedWorksIds,
      informationCircleOutline,
      isNumpadPresented, presentNumpad,
      loadImage,
      colseNumpad,
      t,
      isShareAlertOpen,
      setShareAlertOpen,
      getShareInputs,
      shareButtons,
      setToastOpen,
      isToastOpen,
      initSilder,
      disableSliding,
      background,
      imagesTobeLoaded,
      between,
      onWorkChoosed,
      modalDidPresented,
      currentWorkIndex,
      close,
      isLandscape,
      viewportWidth
    }
  },
  methods: {
    loadImageElement (idName: string, url: string, thumb: string) {
      var img = new Image()
      
      img.onload = function() {
        var imgEl = document.querySelector('#' + idName) as HTMLImageElement
        if(imgEl)
          imgEl.src = BaseUrl + 'xpedeo/' + url
      }
      img.src = BaseUrl + 'xpedeo/' + url
      return thumb
    },
    getWorkTitle(titleString: string) {
      const titles = titleString.split('; ')
      let out = []
      for (const title of titles) {
        out.push(title.replace(/^(.+: )?(.+)(, [^,]+)?$/, '$1<span style="font-style:italic;">$2</span> $3'))
      }
      return out.join('; ')
    }
  }
})
